import React, { useRef, useState } from "react";
import { Typography, TextField, Box, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import useIsMobile from "../../hooks/useIsMobile";
import subscribeToNews from "../../services/Newsletter";
import { getAuthToken, isLoggedIn } from "../../services/Login";
import ReCAPTCHA from "react-google-recaptcha";
import AlertModal from "../alert/Alert";

const Newsletter = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit", // Trigger validation on change
    shouldFocusError: false,
  });

  const { isMobile } = useIsMobile();
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const recaptchaRef = useRef(null);
  const [resetKey, setResetKey] = useState(Date.now());
  const handleRecaptcha = (token: any) => {
    setRecaptchaValue(token);
  };

  const onSubmit = (data: any) => {
    if (!recaptchaValue) {
      setError("Kindly solve recaptcha first");
      return;
    }
    const token = getAuthToken();
    subscribeToNews(data.email, isLoggedIn() && token ? token : recaptchaValue)
      .then((response) => {
        console.log("Subscription successful:", response);
        setMessage(response.message);
        setRecaptchaValue("");
        setResetKey(Date.now());
        setError("");
      })
      .catch((error) => {
        console.error("Error subscribing to news:", error);
        setError("Error subscribing to news: " + error.response.data);
        setMessage("");
        setRecaptchaValue("");
        setResetKey(Date.now());
      });
    reset();

    setError("");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#eee",
        position: "relative",
        overflow: "hidden",
        margin: "6rem",
        padding: "4rem",
        paddingBottom: "5rem",
        width: "80%",
        borderRadius: "25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        textAlign: "center",
        zIndex: 0,
      }}
    >
      <Typography variant="h4">Subscribe to Our Newsletter</Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Stay updated with the latest news and updates. Subscribe to our
        newsletter!
      </Typography>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          type="email"
          label="Enter your email"
          style={{ width: isMobile ? "100%" : "50%", margin: "1rem" }}
          InputProps={{
            sx: { borderRadius: "20px" },
          }}
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^\S+@\S+\.\S+$/,
              message: "Enter a valid email",
            },
          })}
          required={true}
          inputMode="email"
          variant="outlined"
          size="medium"
        />
        {!recaptchaRef && <CircularProgress />}
        {
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={
              process.env.REACT_APP_RECAPTCHA
                ? process.env.REACT_APP_RECAPTCHA
                : ""
            }
            key={resetKey}
            onChange={(token) => handleRecaptcha(token)}
          />
        }
        <button type="submit" style={{ marginTop: "1rem" }}>
          Subscribe
        </button>
      </form>

      {(error.length > 0 || message.length > 0) && (
        <AlertModal
          severity={error.length > 0 ? "error" : "success"}
          text={error.length > 0 ? error : message}
          key={resetKey}
        />
      )}
    </Box>
  );
};

export default Newsletter;
