import * as React from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import useIsMobile from "../../hooks/useIsMobile";
import passport from "../../assets/images/passport.jpg";
import verify from "../../assets/images/verify.png"
import FeatureRow from "./FeatureRow";


export const Features = () => {
  const { isMobile, isTablet, width } = useIsMobile();
  const boxWidth = isMobile ? width - 50 : isTablet ? width / 2 : width / 4;
  const boxHeight = 250;

  const items = [
    {
      id: "0",
      header: "Document Isolation and Quality Evaluation",
      img: passport,
      description:
        "Experience seamless document processing with Whizz's advanced OCR capabilities. Our system excels in isolating documents from images and conducting thorough evaluations to ensure optimum quality. This ensures accurate and reliable results in every document processing task.",
    },
    {
      id: "1",
      header: "Intelligent Document Card Type Validation",
      img: verify,
      description:
        "Whizz employs sophisticated algorithms to intelligently validate document card types. Whether it's identification cards, passports, or other document types, our system employs smart validation techniques to enhance precision and streamline the document recognition process.",
    },
    {
      id: "2",
      header: "Information Extraction as Key-Value Pairs",
      img: passport,
      description:
        "Unlock the power of Whizz's AI document OCR to effortlessly extract information from images. Our system identifies key elements and extracts them as structured key-value pairs, simplifying the extraction of crucial data and enhancing the efficiency of your document processing workflows.",
    },
    {
      id: "3",
      header: "Seamless Arabic to English Data Conversion",
      img: passport,
      description:
        "Whizz goes beyond language barriers with seamless Arabic to English data conversion. Our system excels in accurately converting text from Arabic to English, providing a versatile solution for multilingual document processing. Experience smooth and precise language transition within your documents.",
    },
  ];
  

  return (
    <motion.div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "70%",
        alignItems: "cener",
        justifyContent: "space-between",
        marginTop:'10rem'
      }}
    >
      {items.map((data, index) => (
        <FeatureRow data={data} index={index} key={index}/>
      ))}
    </motion.div>
  );
};

export default Features;
