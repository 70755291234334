import React, { Fragment } from "react";
import { motion } from "framer-motion";


function Highlighter({ Doc,Data, hover, type, highlightAll, withData ,setHover,width,height}: any) {
  
  const ScaleX = width / Doc.img.width
  const ScaleY = height / Doc.img.height;
  
  return (
    <>
      {Data.map((data: any, index: number) => {
        if(data.coordinates && data.coordinates.length>0){

            const radius = data.type && data?.type?.includes("mrz") ? 0 : 5;
            let pathData = ''; 
          try{

            pathData =
            `
            M${data.coordinates[0][0] * ScaleX +radius-1},${data.coordinates[0][1] * ScaleY}
            L${data.coordinates[1][0] * ScaleX - radius},${data.coordinates[1][1] * ScaleY}
          A${radius},${radius} 0 0 1 ${data.coordinates[1][0] * ScaleX},${data.coordinates[1][1] * ScaleY + radius}
          L${data.coordinates[2][0] * ScaleX},${data.coordinates[2][1] * ScaleY - radius}
          A${radius},${radius} 0 0 1 ${data.coordinates[2][0] * ScaleX - radius},${data.coordinates[2][1] * ScaleY}
          L${data.coordinates[3][0] * ScaleX + radius },${data.coordinates[3][1] * ScaleY}
          A${radius},${radius} 0 0 1 ${data.coordinates[3][0] * ScaleX},${data.coordinates[3][1] * ScaleY - radius}
          L${data.coordinates[0][0] * ScaleX },${data.coordinates[0][1] * ScaleY +radius}
          A${radius},${radius} 0 0 1 ${data.coordinates[0][0] * ScaleX+radius},${data.coordinates[0][1] * ScaleY }
          Z
          `;
        }catch(err:any){
          console.error(err)
        }
        
          
          return (
          (data.keyword === hover || highlightAll) &&
          (type === "path" ? (
            <svg
            key={index}
            width="100%"
            height="100%"
            style={{ position: "absolute", top: 0, left: 0 }}
            >
              <motion.path
                d={pathData}
                
                stroke="gold"
                strokeWidth="0.5"
                style={{borderRadius:'20px'}}
                radius={'10px'}
                initial={{ pathLength: 0,fill:"rgba(255,215,0,0)" }}
                animate={{ pathLength: 1 , fill:"rgba(255,215,0,0.3)"}}
                transition={{ duration: 2, ease: "easeInOut" }}
                />
            </svg>
          ) : type === "highlight" ? (
            <svg
            key={index}
            width="100%"
            height="100%"
            style={{ position: "absolute", top: 0, left: 0 }}
            >
              <motion.path
                d={pathData}
                stroke="gold"
                strokeWidth="0.5"
                radius={10}
                initial={{ pathLength: 0,fill:"rgba(255,215,0,0.0)" }}
                animate={{ pathLength: 1 , fill:"rgba(255,215,0,0.3)"}}
                transition={{ duration: 0.5, ease: "easeInOut"}}
                />
            </svg>
           
           ) : (
             <motion.div
             key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="data-highlight"
              onMouseEnter={()=>setHover(data.name)}
              onMouseLeave={()=>(setHover(""))}
              style={{
                position: "absolute",
                top: data.coordinates[0],
                left: data.coordinates[1],
                width:data.coordinates[2],
                height:data.coordinates[3],
                fontWeight: 700,
                fontSize: "12px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "3px solid #FFD700",
                cursor:'pointer',
                // boxShadow:'1px 1px 10px #FFD700',
                borderRadius: "5px",
                backgroundColor: "rgba(255,215,0,0.3)",
              }}
            >
              {withData && data.content}
            </motion.div>
            // <div
            //   key={index}
            //   className="border-highlight"
            //   style={{
            //     position: "absolute",
            //     top: `${data.coordinates[1]}px`, // Position based on the image dimensions
            //     left: data.coordinates[0] + "px",
            //     right: data.coordinates.right + "px",
            //     height: data.coordinates[3] + 10 + "px", // Adjust the height as needed
            //     width: data.coordinates[2] + 10 + "px",
            //     // Ensure the div is visible over the image:
            //     zIndex: 2,
            //   }}
            // />
            ))
            );
          }else{
            return<Fragment key={index}></Fragment>
          }
          })}
          </>
          );
}

export default Highlighter;
