// Import the necessary libraries
import axios from "axios";
import { getAuthToken } from "./Login";

// Define the API endpoint
const apiUrl = process.env.REACT_APP_API_KEY+"web/news/subscribe";

// Define the function to make the API call
const subscribeToNews = async (email: string, recaptchaToken: string) => {
  // Set up the request body
  const requestBody = {
    email: email,
  };

  // Set up the request headers
  const headers = getAuthToken()
    ? { session: recaptchaToken, "Content-Type": "application/json" }
    : {
        "recaptcha-token": recaptchaToken,
        "Content-Type": "application/json",
      };

  try {
    // Make the API call using axios
    const response = await axios.post(apiUrl, requestBody, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error("Error subscribing to news:", error);
    throw error;
  }
};

export default subscribeToNews;
