import React from "react";
import {  Grid,
} from "@mui/material";
import Demo from "../../components/demo/Demo";
import Footer from "../../components/footer/Footer";

const DemoPage = () => {

  return (
  <div id="demo">
  
   <Grid container alignItems={'center'} justifyContent={'center'} pt={2} pb={10}>
      
        <Demo showAnimation={true} />
    
    </Grid>
    <Footer/>
    </div>
  );
};

export default DemoPage;
