import axios from "axios";

// services/fetchData.js
const fetchData = async () => {
    // Simulating API call with a delay
    const url =
    window.location.protocol + "//" + window.location.host + "/config.json";
  const response = await axios.get<any>(url);

  return response.data;
  };
  
  export default fetchData;
  