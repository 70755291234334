import { useState } from "react";
import { fetchOcrResult } from "../services/demoService";

export const useOcr = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [ocrResult, setOcrResult] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageStarted, setImageStarted] = useState<any>(false);

  const reset = ()=>
  {
    setImageStarted(false)
    setImageUrl("")
    setOcrResult(null)
    setProgress(0)
    setLoading(false)
    setError(null)
  }
  const handleImageChange = async(event: any) => {
    setImageStarted(true)
    reset();
    const file = event;
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      setOcrResult(null);
      setError(null);
    }
          // Get image size information
          const image = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            // reader.onprogress = (event) => {
            //   if (event.lengthComputable) {
            //     const progress = (event.loaded / event.total) * 100;
            //     setProgress(progress);
            //   }
            // };
        
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
    
  };

  const handleOcrRequest = async (token: any, reference: any) => {
    setLoading(true);
    setError(null);

    try {
      const result = await fetchOcrResult(
        new Blob([await fetch(imageUrl).then((response) => response.blob())], {
          type: "image/*",
        }),
        token,
        reference,
        setProgress
      );
      setOcrResult(result);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    imageStarted,
    progress,
    imageUrl,
    ocrResult,
    loading,
    error,
    handleImageChange,
    handleOcrRequest,
    reset
  };
};

export default useOcr;
