import React, { useRef } from 'react';
import CTA from '../../components/cta/CTA';
import Demo from '../../components/demo/Demo';
import Footer from '../../components/footer/Footer';
import './LandingPage.css'
import Newsletter from '../../components/Newsletter/Newsletter';
import Features from '../../components/features/Features';
import Plans from '../../components/plans/Plans';
import { getAuthToken } from '../../services/Login';
import passport from "../../assets/images/passport.jpg";

import { Helmet } from 'react-helmet';
function LandingPage()
{

  const DemoRef = useRef<HTMLDivElement>(null);

  const scrollToElement = () =>
  {
    if (DemoRef.current)
    {
      DemoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id="landing">
    
    <Helmet>
        <title>Whizz - Empowering Document AI</title>
        <meta
          name="description"
          content="Whizz delivers cutting-edge Lebanese ID OCR technology for streamlined e-KYC integration. Elevate your business with our intelligent document processing solutions."
        />
        <meta
          property="og:title"
          content="Advanced Lebanese ID OCR | e-KYC Solutions | Whizz AI"
        />
        <meta
          property="og:description"
          content="Transform your verification process with Whizz's AI-driven Lebanese ID OCR technology and e-KYC integration for enhanced efficiency and accuracy."
        />
        <meta property="og:image" content={passport} />
        <meta
          name="twitter:title"
          content="Lebanese ID OCR Expertise | e-KYC by Whizz AI"
        />
        <meta
          name="twitter:description"
          content="Experience the future of verification with Whizz's Lebanese ID OCR and e-KYC solutions—where technology meets compliance and convenience."
        />
        <meta name="twitter:image" content={passport} />
      </Helmet>


    <div className="LandingPage_Container col aligncenter justifybetween">
      <CTA scroll={ scrollToElement } />
      {!getAuthToken() ? <Newsletter /> : <><br/><br/></>}
      <Demo refe={ DemoRef } />
      <Features />

      {/* <Plans /> */}
      <Footer />

    </div>
    </div>
  );
}

export default LandingPage;