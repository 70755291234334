import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./Demo.css";
import lightThemeOptions from "../../theme/lightThemeOptions";
import {
  Box,
  FormControlLabel,
  LinearProgress,
  LinearProgressProps,
  Switch,
  Typography,
  createTheme,
} from "@mui/material";
import DemoAnimation from "./DemoAnimation";
import useIsMobile from "../../hooks/useIsMobile";
import sampleImg from "../../assets/images/sampleImg.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import useOcr from "../../hooks/useDemo";
import AlertModal from "../alert/Alert";
import { DocumentData } from "../../constants/SambleData";
import { getAuthToken, isLoggedIn } from "../../services/Login";

const theme = createTheme(lightThemeOptions);

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35, mt: 1.5 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function Demo({ refe, showAnimation = true }: any) {
  const [hover, setHover] = useState("");
  const [showDrop, setShowDrop] = useState<any>(false);
  const [language, setLanguage] = useState("ar");
  const [resetKey, setResetKey] = useState(Date.now());

  const [recaptchaValue, setRecaptchaValue] = useState<any>(null);
  const { isMobile, isTablet, width } = useIsMobile();
  const handleSwitchChange = () => {
    setLanguage((prev: any) => (prev === "ar" ? "en" : "ar"));
  };
  const DemoRef = useRef<HTMLDivElement>(null);

  const scrollToElement = () => {
    if (DemoRef.current) {
      DemoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const {
    progress,
    imageUrl,
    ocrResult,
    loading,
    error,
    handleImageChange,
    handleOcrRequest,
    reset,
  } = useOcr();
  const [customError, setError] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const handleConfirm = async () => {
    scrollToElement();
    if (isLoggedIn() || (!isLoggedIn() && recaptchaValue)) {
      setShowProgress(true);

      try {
        await handleOcrRequest(
          isLoggedIn() ? getAuthToken() : recaptchaValue,
          "123123"
        );
      } catch (error: any) {
        setResetKey(Date.now());
      }
    } else {
      setError("Please Solve Recaptcha first");
      setShowProgress(false);
    }
  };
  useEffect(() => {
    if (progress === 100) setShowDrop(false);
  }, [progress]);
  const recaptchaRef = useRef(null);

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      handleImageChange(file);
      const reader = new FileReader();

      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".webp", ".heic"],
    },
  });
  const handleRecaptcha = (token: any) => {
    setRecaptchaValue(token);
    setError("");
  };

  return (
    <section className="demo-section" style={{}}>
      <div
        ref={refe}
        className="col aligncenter justifybetween"
        style={{ width: isMobile || isTablet ? "80%" : "50%" }}
      >
        <h2 ref={DemoRef}>Try our demo now!</h2>
        <p>
          {
            "Experience our flagship project in ID OCR through our interactive demo.\n This section showcases the capability of our technology to accurately process and recognize information from various documents."
          }
        </p>

        <button
          style={{
            backgroundColor: loading ? "#eee" : theme.palette.primary.main,
            cursor: loading ? "default" : "pointer",
          }}
          disabled={loading}
          onClick={() => {
            setShowDrop(true);
            setShowProgress(false);
            reset();
          }}
        >
          Try Now
        </button>

        {!isLoggedIn() && showDrop && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={
              process.env.REACT_APP_RECAPTCHA
                ? process.env.REACT_APP_RECAPTCHA
                : ""
            }
            key={resetKey}
            onChange={(token) => handleRecaptcha(token)}
          />
        )}

        {showDrop && (
          <div
            {...getRootProps()}
            className="dropzone"
            style={{
              margin: "1rem",
              backgroundColor: "#eee",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              borderRadius: "15px",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} accept="" disabled={loading} />

            <p>Drag an image here, or click to select an image</p>
          </div>
        )}
        <div style={{ width: "50%" }}>
          {imageUrl && showProgress && progress < 100 && (
            <Typography>uploading Image..</Typography>
          )}
          {imageUrl && showProgress && (
            <LinearProgressWithLabel
              style={{ width: "100%" }}
              value={progress}
            />
          )}
        </div>

        {showDrop && imageUrl && !showProgress && (
          <>
            <img
              src={imageUrl}
              style={{
                width: isMobile ? width - 50 : isTablet ? width / 2 : width / 4,
                height: "100%",
                borderRadius: "15px",
              }}
              alt="ocr"
            />
            <button onClick={handleConfirm} disabled={loading}>
              Confirm
            </button>
          </>
        )}
      </div>
      <div
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {((!error && imageUrl && !showDrop) || showAnimation) && (
          <FormControlLabel
            control={<Switch color={"primary"} onChange={handleSwitchChange} />}
            label="English"
          />
        )}
      </div>
      {showAnimation && !(imageUrl && !showDrop) && (
        <DemoAnimation
          originalImg={sampleImg}
          data={DocumentData}
          hover={hover}
          setHover={setHover}
          language={language}
        />
      )}
      {!error && imageUrl && !showDrop && progress === 100 && (
        <DemoAnimation
          originalImg={imageUrl}
          data={ocrResult}
          hover={hover}
          setHover={setHover}
          language={language}
        />
      )}
      {(error || (customError && customError.trim().length > 0)) && (
        <AlertModal
          severity="error"
          key={resetKey}
          text={error ? error : customError}
        />
      )}
    </section>
  );
}

export default Demo;
