import "./App.css";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import MyErrorBoundary from "./components/alert/ErrorBoundary";
import Dashboard from "./Dashboard";
function App()
{
  
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/*" element={<Dashboard />}/>
    )
  );
  return (
    <>
      <MyErrorBoundary>
        <RouterProvider router={router} />
     </MyErrorBoundary>
    </>
  );
}

export default App;
