import React from 'react';
import './Footer.css'
function Footer() {
  return (
    <footer className="footer-section">
      <p>&copy; {new Date().getFullYear()} Whizz. All rights reserved.</p>
    </footer>
  );
}

export default Footer;