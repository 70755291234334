// actions
const OPEN_SNACKBAR = "app/OPEN_SNACKBAR";
const CLOSE_SNACKBAR = "app/CLOSE_MODAL";


const DEFAULT_STATE = {
  mainSelected: null,

  snackBarOpen: false,
  snackBarContent: null,
  snackBarSeverity: "info",
  snackBarPersist: false,

};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: true,
        snackBarContent: action.snackBarContent,
        snackBarSeverity: action.snackBarSeverity,
        snackBarPersist: action.persist ? action.persist : false,
      });

    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: false,
        snackBarContent: null,
        snackBarSeverity: "info",
      });
    

    default:
      return state;
  }
};

export default reducer;

// action creators
export const openSnackBar = (
  snackBarContent: string,
  snackBarSeverity: string,
  persist?: boolean
) => ({
  snackBarContent,
  snackBarSeverity,
  persist,
  type: OPEN_SNACKBAR,
});

export const closeSnackBar = () => ({
  snackBarContent: null,
  type: CLOSE_SNACKBAR,
});

