// contactUsService.ts
import axios from 'axios';
import { getAuthToken } from './Login';

export interface ContactUsData {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumberCountryCode: string;
  phoneNumber: string;
  companyName: string;
  numberOfEmployees: number;
  country: string;
  message: string;
}

const contactUsService = async (contactData: ContactUsData, recaptchaToken: string) => {
  const apiUrl = process.env.REACT_APP_API_KEY+'web/contact-us';

  // Set up the request headers
  const headers = getAuthToken()
  ? { session: recaptchaToken, "Content-Type": "application/json" }
  : {
      "recaptcha-token": recaptchaToken,
      "Content-Type": "application/json",
    };
  try {
    // Make the POST request using axios
    const response = await axios.post(apiUrl, contactData, { headers });

    // Return the response data
    return response.data;
  } catch (error:any) {

    console.error('Error contacting us:', error?.response?.data);
    throw error;
  }
};

export default contactUsService;
