import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Highlighter from "./Highlighter";
import ExtractedData from "./ExtractedData";
import ScanningEffect from "./ScanningEffect";
import useIsMobile from "../../hooks/useIsMobile";

const DemoAnimation = ({
  originalImg,
  data,
  hover,
  setHover,
  language,
}: any) => {
  const { isMobile, isTablet, width } = useIsMobile();
  const ImgWidth = data?.docdetails[0]?.document.img.width;
  const ImgHeight = data?.docdetails[0]?.document.img.height;
  const boxWidth = isMobile ? width - 50 : isTablet ? width / 2 : width / 4;
  const boxHeight = ImgHeight ? (boxWidth * ImgHeight) / ImgWidth : 250;

  const halfImageStyle = {
    width: "100%",
    height: "100%",
    backgroundSize: "100% 100%",
    backgroundPosition: "0% 0%",
  };
  const boxStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "#eee",
    width: boxWidth,
    minHeight: boxHeight,
    marginLeft: isMobile || isTablet ? 0 : 30,
    marginBottom: isMobile || isTablet ? 30 : 0,
    borderRadius: 20,
    zIndex: 0,
  };
  const [currentStage, setCurrentStage] = useState(1);

  useEffect(() => {
    if (
      data &&
      (!data.docdetails ||
        !data.docdetails[0] ||
        !data.docdetails[0].document ||
        !data.docdetails[0].details)
    ) {
      console.error("Missing required data fields!");
      // Potentially display a user-friendly error message
      return;
    }

    if (data) {
      const intervalId = setInterval(() => {
        setCurrentStage((prevStage) => {
          const nextStage = (prevStage % 6) + 1;

          if (nextStage === 6) {
            clearInterval(intervalId);
          }

          return nextStage;
        });
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile || isTablet ? "column" : "row",
        marginTop: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ ...boxStyles, position: "relative", overflow: "hidden" }}>
        {currentStage === 1 && <ScanningEffect />}
        <motion.div
          style={{
            ...halfImageStyle,
            backgroundImage: `url(${originalImg})`,
            position: "absolute",
            zIndex: 1,
            borderRadius: "20px",
          }}
          transition={{ duration: 1, ease: "easeInOut" }}
        />
        <motion.div
          style={{
            ...halfImageStyle,
            zIndex: 0,
          }}
          initial={isMobile || isTablet ? { y: 0 } : { x: 0 }}
          animate={
            isMobile || isTablet
              ? { y: currentStage === 1 ? 0 : boxHeight }
              : { x: currentStage === 1 ? 0 : boxWidth }
          }
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          {data && (
            <img
              src={`data:image/png;base64, ${data?.docdetails[0]?.document?.img.encoded_image}`}
              style={{
                width: boxWidth,
                height: isMobile ? boxHeight - 5 : boxHeight,
                objectFit: "fill",
                borderRadius: "20px",
              }}
              alt="Base64"
            />
          )}
        </motion.div>
      </div>
      <div
        style={{
          ...boxStyles,
          position: "relative",
        }}
      >
        {currentStage === 3 && <ScanningEffect />}
        <motion.div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
          animate={
            isMobile || isTablet
              ? { y: currentStage === 6 ? boxHeight : 0 }
              : { x: currentStage === 6 ? boxWidth : 0 }
          }
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          {(currentStage === 3 || currentStage === 4 || currentStage === 6) && (
            <Highlighter
              Doc={data?.docdetails[0]?.document}
              Data={data?.docdetails[0]?.details}
              hover={hover}
              setHover={setHover}
              type={
                currentStage === 4 || currentStage === 6 ? "highlight" : "path"
              }
              highlightAll={true}
              width={boxWidth}
              height={boxHeight}
            />
          )}
           {data?.docdetails[0]?.document?.mrz && (currentStage > 2 ) && (
            <Highlighter
              Doc={data?.docdetails[0]?.document}
              Data={[data?.docdetails[0]?.document.mrz]}
              hover={hover}
              setHover={setHover}
              type={
                currentStage === 4 || currentStage === 6 ? "highlight" : "path"
              }
              highlightAll={true}
              width={boxWidth}
              height={boxHeight}
            />
          )}
        </motion.div>
        {currentStage !== 1 && (
          <Highlighter
            Doc={data?.docdetails[0]?.document}
            Data={data?.docdetails[0]?.details}
            hover={hover}
            setHover={setHover}
            type={"highlight"}
            highlightAll={false}
            width={boxWidth}
            height={boxHeight}
          />
        )}

        <motion.div
          animate={
            isMobile || isTablet
              ? { y: currentStage === 1 ? -boxHeight : 0 }
              : { x: currentStage === 1 ? -boxWidth : 0 }
          }
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          {data && (
            <img
              src={`data:image/png;base64, ${data?.docdetails[0]?.document?.img.encoded_image}`}
              style={{ width: boxWidth, height: boxHeight, objectFit: "fill" }}
              alt="Base64"
            />
          )}
        </motion.div>

        {(currentStage === 5 || currentStage === 6) && (
          <motion.div
            style={{
              ...halfImageStyle,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            initial={{ x: 0, scale: 0.1, opacity: 0 }}
            animate={
              isMobile || isTablet
                ? {
                    y: currentStage === 6 ? boxHeight + 10 : 0,
                    scale: 1,
                    width: "100%",
                    height: "100%",
                    opacity: 1,
                  }
                : {
                    x: currentStage === 6 ? boxWidth : 0,
                    scale: 1,
                    width: "100%",
                    height: "100%",
                    opacity: 1,
                  }
            }
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            <ExtractedData
              Data={data?.docdetails[0].details}
              setHover={setHover}
              height={boxHeight}
              language={language}
            />
          </motion.div>
        )}
      </div>
      <div
        style={{
          ...boxStyles,
          minHeight: boxHeight,
          position: "relative",
        }}
      >
        {currentStage === 6 && (
          <motion.div
            style={{
              width: "100%",
              height: "100%",
              backgroundSize: "100% 100%",
              backgroundPosition: "0% 0%",
              zIndex: 2,
            }}
            initial={
              isMobile || isTablet ? { y: -boxHeight } : { x: -boxWidth }
            }
            animate={isMobile || isTablet ? { y: 0, x: 10 } : { x: 10 }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            <ExtractedData
              Data={data?.docdetails[0].details}
              setHover={setHover}
              height={boxHeight}
              language={language}
            />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default DemoAnimation;
