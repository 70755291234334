import * as React from "react";
import { motion } from "framer-motion";
import useIsMobile from "../../hooks/useIsMobile";
import FeatureRow from "../../components/features/FeatureRow";
import passport from "../../assets/images/passport.jpg";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const {width} = useIsMobile();
  const boxWidth = width - 50;

  const aboutUsData = {
    introduction: "Welcome to Whizz!",
    mission: "Empowering Smarter Document Handling",
    missionDetails:
      "Our mission is to empower individuals and businesses with intelligent document processing solutions. By combining advanced Artificial Intelligence techniques with a user-friendly interface, we strive to simplify and enhance the document handling experience.",
    whatSetsUsApart: {
      title: "What Sets Us Apart",
      description:
        "Focused Expertise: Specializing in Document AI, we bring a focused expertise that ensures precision and excellence in our solutions." +
        "Continuous Expansion: We are continuously expanding our services to support a growing list of IDs. Currently, we are proud to offer accurate processing for Lebanese IDs, with plans to include English mapping for Arabic and introduce face matching technology in the near future." +
        "Impressive Accuracy: With a remarkable 98% accuracy rate, our technology stands out for its reliability and efficiency.",
      img: passport,
    },
    exploreDemo: {
      title: "Explore Our Interactive Demo",
      description:
        "Explore our interactive demo to witness the capabilities of our technology. Our demo allows you to experience firsthand how our AI system accurately processes and recognizes information from various documents.",
      demoLink: "/demo",
      img: passport,
    },
    pricingDetails: {
      title: "Pricing Details",
      description:
        "Our pricing framework is tailored for versatility, scaling to match diverse demands and expanding business needs. We offer customizable packages that adapt to various document types and usage volumes, ensuring flexible and economical options for every client.",
      img: passport,
    },
    contactUs: {
      title: "Contact Us",
      description:
        "Have questions or want to learn more about our services? Feel free to reach out to our sales team. We are here to assist you!",
      contactLink: "/contact",
      img: passport,
    },
    subscribeUpdates: {
      title: "Subscribe for Updates",
      description:
        "Subscribe to our newsletter to stay informed about the latest updates, new services, and enhancements. We have exciting developments in the pipeline, and we want you to be the first to know.",
      subscribeLink: "[Subscribe Now]",
      img: passport,
    },
  };

  return (
    <div id="aboutus">
             <Helmet>

        <title>About Us - Whizz</title>
        <meta name="description" content="Empowering Smarter Document Handling at Whizz" />
        <meta property="og:title" content="About Us - Whizz" />
        <meta property="og:description" content="Empowering Smarter Document Handling at Whizz" />
        <meta property="og:image" content={passport} />
        <meta name="twitter:title" content="About Us - Whizz" />
        <meta name="twitter:description" content="Empowering Smarter Document Handling at Whizz" />
        <meta name="twitter:image" content={passport} />
      </Helmet>
    <div style={{ overflowX: "hidden", position: "relative",paddingTop:'1rem' }}>
      <motion.div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: boxWidth,
          margin: "auto",
        }}
      >
        <motion.h1>{aboutUsData.introduction}</motion.h1>
        <motion.h2>{aboutUsData.mission}</motion.h2>
        <motion.p style={{ width: "70%" }}>
          {aboutUsData.missionDetails}
        </motion.p>

        <div style={{ margin: "3rem", maxWidth: "80%" }}>
          <FeatureRow data={aboutUsData.whatSetsUsApart} index={0} />
        </div>
        <div style={{ margin: "3rem", maxWidth: "80%" }}>
          <FeatureRow data={aboutUsData.exploreDemo} index={1} />
        </div>
        <div style={{ margin: "3rem", maxWidth: "80%" }}>
          <FeatureRow data={aboutUsData.pricingDetails} index={2} />
        </div>
        <div style={{ margin: "3rem", maxWidth: "80%" }}>
          <FeatureRow data={aboutUsData.contactUs} index={3} />
        </div>
        <div style={{ margin: "3rem", maxWidth: "80%" }}>
          <FeatureRow data={aboutUsData.subscribeUpdates} index={4} />
        </div>
      </motion.div>
    </div>
    </div>
  );
};

export default AboutUs;
