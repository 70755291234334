import { ThemeOptions } from "@mui/material/styles";

const lightThemeOptions: ThemeOptions = {
  direction: "ltr",
  palette: {
    primary: {
      light: "#f53254",
      main: "#eb0029",
      dark: "#b90827",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FFFFFF",
      main: "#FFFFFF",
      dark: "#FFFFFF",
      contrastText: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#F9F9F9",
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export default lightThemeOptions;
