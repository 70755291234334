import React, { useRef, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Autocomplete,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import contactUsService from "../../services/ContactService";
import ReCAPTCHA from "react-google-recaptcha";
import { countries } from "../../constants/JsonObjects";
import AlertModal from "../../components/alert/Alert";
import useIsMobile from "../../hooks/useIsMobile";
import Footer from "../../components/footer/Footer";
import Helmet from "react-helmet";

// JSON objects for form fields
const formFields = [
  {
    label: "Full Name",
    name: "fullName",
    type: "text",
    validation: { required: "Full Name is required" },
  },
  {
    label: "Email",
    name: "email", 
    type: "email",
    validation: { required: "Email is required" },
  },
  {
    label: "Company Name",
    name: "companyName",
    type: "text",
  },
  {
    label: "Number of Employees",
    name: "numberOfEmployees",
    type: "number",
  },
  {
    label: "Phone number",
    name: "country",
    type: "autocomplete",
  },

  {
    label: "Message",
    name: "message",
    type: "multiline",
    validation: { required: "Message is required" },
  },
];

const ContactSalesForm = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isMobile, isTablet } = useIsMobile();
  const web = !isMobile && !isTablet;
  const [phoneCode, setPhoneCode] = useState("+961");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [resetKey, setResetKey] = useState(Date.now());
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const recaptchaRef = useRef(null);

  const handleRecaptcha = (token: any) => {
    setRecaptchaValue(token);
  };

  const onSubmit = async (data: any) => {
    try {
      const dataSplit = data.fullName.split(" ");
      data.firstName = dataSplit[0];
      data.lastName =
        dataSplit.length > 1 ? dataSplit[dataSplit.length - 1] : "";
      data.phoneNumberCountryCode = phoneCode.slice(1);
      data.phoneNumber = data.country;
      data.country = countries.find((item) => item.phone === phoneCode)?.code;
      // if(data.country === "LB") data.country = "LBN"
      delete data.fullName;
      await contactUsService(data, recaptchaValue)
        .then((response) => {
          console.log("Message sent successfully: ", response);
          setError("")
          setResetKey(Date.now());
          setMessage( response.message);
          setRecaptchaValue("");
        })
        .catch((error) => {
          setError("Error Sending message: " + error?.response?.data);
          setMessage("")
          setRecaptchaValue("");
          setResetKey(Date.now());
        });
    } catch (error: any) {
      console.error(
        "Error submitting contact us request: ",
        error?.response?.data
      );
      setError("Error Sending message: " + error?.response?.data);

      setResetKey(Date.now());
    }
  };

  return (
    <div
      style={{
        maxWidth: "100%",
        position: "relative",
        overflowX: "hidden",
      }}
    >
      <Container
        style={{
          marginTop: "20px",
          width: "100%",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Helmet>
          <title>Contact Sales - Whizzocr</title>
          <meta
            name="description"
            content="Contact our sales team to learn more about our products and services."
          />
          <meta
            name="keywords"
            content="sales, contact, inquiry, products, services"
          />
        </Helmet>

        <h2>Contact Sales</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: web ? "60%" : "80%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Render form fields dynamically from JSON objects */}
          {formFields.map((field, index) => (
            <React.Fragment key={index}>
              {field.type === "autocomplete" ? (
                <Grid mt={1} container minWidth={"100%"} spacing={1}>
                  <Grid item xs={web ? 6 : 4} md={2}>
                    <Controller
                      key={index}
                      name={field.name}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          id={`country-select-demo`}
                          sx={{ width: "100%" }}
                          options={countries}
                          autoHighlight
                          autoComplete={true}
                          disableClearable
                          // size="small"
                          value={countries.find(
                            (item) => item.phone === phoneCode
                          )}
                          onChange={(event, newValue) => {
                            setPhoneCode(newValue?.phone);
                          }}
                          getOptionLabel={(option) => option.phone}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                              key={option.code}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                              ({option.code}) {option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={phoneCode}
                              id="outlined-basic"
                              // size="small"
                              variant="outlined"
                              onChange={(event) =>
                                setPhoneCode(event.target.value)
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              InputProps={{
                                ...params.InputProps,
                                sx: { borderRadius: "15px", display: "flex" },
                                endAdornment: "",
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={web ? 6 : 8} md={10}>
                    <TextField
                      id="outlined-basic"
                      label={field.label}
                      variant="outlined"
                      // size="small"
                      type="number"
                      {...register(field.name, field.validation)}
                      style={{ width: "100%" }}
                      InputProps={{ style: { borderRadius: "15px" } }}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message?.toString()}
                    />
                  </Grid>
                </Grid>
              ) : (
                <TextField
                  label={field.label}
                  type={field.type}
                  {...register(field.name, field.validation)}
                  fullWidth
                  required={field.validation?.required ? true : false}
                  margin="normal"
                  // size="small"
                  variant="outlined"
                  InputProps={{ style: { borderRadius: "15px" } }}
                  error={!!errors[field.name]}
                  inputProps={{ min: 1 }}
                  helperText={errors[field.name]?.message?.toString()}
                />
              )}
            </React.Fragment>
          ))}

          {!recaptchaRef && <CircularProgress />}

          <div
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              key={resetKey}
              sitekey={
                process.env.REACT_APP_RECAPTCHA
                  ? process.env.REACT_APP_RECAPTCHA
                  : ""
              }
              onChange={(token: any) => handleRecaptcha(token)}
            />
          </div>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              width: "80%",
              borderRadius: "15px",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Submit
          </Button>
        </form>

        {(error.length > 0 || message.length > 0) && (
          <AlertModal
            severity={error.length > 0 ? "error" : "success"}
            text={error.length > 0 ? error : message}
            key={resetKey}
          />
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default ContactSalesForm;
