import React, { useState } from "react";
import logo from "../../assets/logo.svg";
import "./Navbar.css";
import { menuItems, menuItemsAuthed } from "../../constants/JsonObjects";
import { Button, Typography, createTheme, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { getAuthToken, removeAuthToken } from "../../services/Login";

const theme = createTheme(lightThemeOptions);


function Navbar({ documentItems }: any) {
  const navigate = useNavigate();
  const primary = theme.palette.primary;
  const menu = getAuthToken() ? menuItemsAuthed : menuItems;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="Navbar_container">
      <div className="Navbar_col">
        <div
          className="row aligncenter"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <img alt="logo" src={logo} className="logo" />
          <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
            Whizz
          </Typography>
        </div>
      </div>
      <div className="Navbar_col" style={getAuthToken() ? { width: "20%" } : { width: "30%" }}>
        {menu.slice(0, -1).map((item: any, index: any) => {
          if (item.name === "Supported IDs") {
            return (
              <div key={index} onMouseEnter={handleMenuOpen} onMouseLeave={handleMenuClose}>
                <Typography
                  className="Navbar_menuItem"
                >
                  {item.name}
                </Typography>
                <Menu
                  id="supported-ids-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  onMouseLeave={handleMenuClose}
                  MenuListProps={{ onMouseLeave: handleMenuClose }}

                >
                  {documentItems?.map((docItem:any, docIndex:any) => (
                    <MenuItem
                      key={docIndex}
                      onClick={() => {
                        handleMenuClose();
                        navigate(docItem.Endpoint)
                      }}
                    >
                      {docItem.Title}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            );
          } else {
            return (
              <Typography
                key={index}
                className="Navbar_menuItem"
                onClick={() => navigate(item.url)}
              >
                {" "}
                {item.name}
              </Typography>
            );
          }
        })}
      </div>
      <div className="Navbar_col" style={{ justifyContent: "flex-end" }}>
        {getAuthToken() ? (
          <Button
            onClick={() => removeAuthToken()}
            sx={{
              backgroundColor: "#fff",
              color: primary.main,
              width: "15%",
              "&:hover": {
                backgroundColor: "#eee",
                transition: "0.5s",
              },
            }}
            style={{ borderRadius: 10 }}
          >
            Logout
          </Button>
        ) : (
          <Button
            onClick={() => navigate("/login")}
            sx={{
              backgroundColor: "#fff",
              color: primary.main,
              width: "15%",
              "&:hover": {
                backgroundColor: "#eee",
                transition: "0.5s",
              },
            }}
            style={{ borderRadius: 10 }}
          >
            Login
          </Button>
        )}
      </div>
    </div>
  );
}

export default Navbar;

