import React from "react";
import "./CTA.css";
import ai from "../../assets/images/ai.jpg";
import { TypeAnimation } from "react-type-animation";

function CTA({scroll}:any) {
  return (
    <section className="cta-section">
      <div className="col aligncenter justifybetween CTA_LEFT">
        <h2>Ready to Get Started?</h2>

        <TypeAnimation
          sequence={[
            // "Welcome to our landing page!\n We are an AI company specializing in Document AI" +
            //   " currently focusing on Lebanese ID among a list of supported IDs.\n Our services are " +
            //   "expanding rapidly, adding English mapping for Arabic, and face matching technology is" +
            //   " coming soon. \nWe have a 98% accuracy rate.",
             "Welcome! Pioneering the Document AI scene, we excel in handling Lebanese ID"+ 
             " and a variety of others. Our suite is expanding featuring English-Arabic mapping" + 
             " and soon face matching. Speedy and precise with a 98% success rate, we're reshaping " + 
             "document processing. Dive in!",
            500, // Wait 1 second before starting the next sentence
          ]}
          wrapper="div"
          cursor={true}
          repeat={Infinity}
          style={{
            whiteSpace: "pre-line",
            fontSize: 18,
            maxWidth: "65%",
            marginBottom: "1rem",
          }} // This style property will ensure that new lines start after each sentence
        />
        <button onClick={scroll}>
          Try our demo
        </button>
      </div>
      <div className="col aligncenter justifybetween CTA_RIGHT" style={{}}>
        <img
          alt="cta"
          src={ai}
          style={{
            width: "60%",
            height: "60%",
            objectFit: "contain",
            borderRadius: 20,
          }}
        />
      </div>
    </section>
  );
}

export default CTA;
