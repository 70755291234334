import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }: any) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.primary,
  // border: "3px solid rgba(255,215,0,0.5)",
  borderRadius: "20px",
  boxShadow: "0px 0px 20px rgba(255, 215, 0, 0.7)",
  backdropFilter: "blur(10px)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const lightTheme = createTheme({ palette: { mode: "light" } });

export default function ExtractedData({
  Data,
  setHover,
  height,
  language,
}: any) {
  return (
    <ThemeProvider theme={lightTheme}>
      <Grid
        container
        spacing={1}
        width={"100%"}
        padding={1}
        style={{ minHeight: height ? height : "250px" }}
      >
        {Data.map((item: any, index: any) => (
          <Grid
            item
            key={index}
            xs={6}
            className={language === "ar" ? "data-rotated" : "data-flip"}
            md={
              item.keyword === "image"
                ? 3
                : index === 1 &&
                  Data.find((item: any) => item.keyword === "image")
                ? 9
                : Data.length % 2 === 1 && index === Data.length - 1
                ? 12
                : 6
            }
            minHeight={
              index === 0 && Data.find((item: any) => item.keyword === "image")
                ? "80px"
                : "100%"
            }
          >
            <Item
              key={index}
              style={{
                cursor: "pointer",
                borderRadius: "10px",
                 
              }}
              onMouseEnter={() => setHover(item.keyword)}
              onMouseLeave={() => setHover("")}
            >
              <span
                className={language === "ar" ? "data-rotated2" : "data-flip2"}
                style={{ fontWeight: 700 }}
              >
                {item.label}
              </span>
              <span
                className={language === "ar" ? "data-rotated2" : "data-flip2"}
              >
                {language === "ar" && item.ar ? item.ar : item.data}
              </span>
            </Item>
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
}
