import axios from 'axios';

const BASE_URL = 'users/auth/login/';


export const setAuthToken = (token: string) => {
    localStorage.setItem('token', token);
  };
  
  // Function to get the user token from local storage
  export const getAuthToken = () => {
    return localStorage.getItem('token');
  };
  
  // Function to remove the user token from local storage
  export const removeAuthToken = () => {
    localStorage.removeItem('token');
    window.location.reload()
  };
  
  // Function to check if the user is logged in
  export const isLoggedIn = () => {
    return !!getAuthToken();
  };

export const login = async (username : any, password : any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_KEY}${BASE_URL}`, {
      username,
      password,
    });
    
    if (response.data){
        setAuthToken(response.data.id)
    }
    return response.data;
  } catch (error:any) {
    throw error
  }
};
