import { CircularProgress } from '@mui/material'
import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes, ScrollRestoration } from 'react-router-dom'
import LandingPage from './pages/landingPage/LandingPage'
import IdCards from './pages/idsPage/IdsPage'
import AboutUs from './pages/About Us/AboutUs'
import ContactSalesForm from './pages/Contact Sales/ContactSales'
import RegistrationForm from './components/register/Register'

import DemoPage from './pages/Demo/DemoPage'
import Navbar from './components/navbar/Navbar'
import MobileMenu from './components/mobilemenu/MobileMenu'
import useIsMobile from './hooks/useIsMobile'
import fetchData from './services/fetchIdsData'
import Login from './pages/Login/Login'

function Dashboard() {
    const { isMobile, isTablet, width } = useIsMobile();
    const web = !isMobile && !isTablet
  
  const [menuItems, setMenuItems] = useState<any>(null);

  useEffect(() => {
    const getData = async () => {
      const result = await fetchData();
      setMenuItems(result.Documents);
    };

    getData();
  }, []);
  return (
    <div id="dashboard">
{ web || width > 900 ? <Navbar documentItems={menuItems}/> : <MobileMenu documentItems={menuItems}/> }

    <Suspense fallback={<CircularProgress />}>
    <ScrollRestoration />

    <Routes>
          <Route path="/" element={<LandingPage />}/>
          <Route path="/:id" element={ <IdCards /> } />
          <Route path="/aboutus" element={ <AboutUs /> } />
          <Route path="/contact" element={ <ContactSalesForm /> } />
          {/* <Route path="/register" element={ <RegistrationForm /> } /> */}
          <Route path="/login" element={ <Login /> } />
          <Route path="/demo" element={ <DemoPage /> } />
    </Routes>
  </Suspense>
    </div>
  )
}

export default Dashboard
