import axios from "axios";
import { getAuthToken } from "./Login";

export const fetchOcrResult = async (
  file: any,
  token: any,
  reference: any,
  setProgress: any
) => {
  try {
    
    const formData = new FormData();
    formData.append("file", file);
    const url = getAuthToken()
      ? process.env.REACT_APP_API_KEY+"web/ocr"
      : process.env.REACT_APP_API_KEY+"web/demo/ocr";
    const response = await axios.post(url, formData, {
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },

      params: { reference },
      headers: getAuthToken()
        ? {
            "Content-Type": "multipart/form-data",
            session: token,
          }
        : {
            "Content-Type": "multipart/form-data",
            "recaptcha-token": token,
          },
    });

    // Check for "failed" status in the response
    if (
      response.data.status === "failed" ||
      response.data.status === "error" ||
      response.data.message === "Model failed to detect any supported document!"
    ) {
      const errorMessage =
        response.data.message || "Model failed to detect any document!";
      console.error("OCR failed:", errorMessage);

      // Handle the failure, you can throw an error or return a specific value
      throw new Error(errorMessage);
    }

    // If the status is not "failed," return the response data
    return response.data;
  } catch (error: any) {
    console.error("OCR request error:", error);

    // Rethrow the error or handle it as needed
    throw new Error(
      error.message || "An error occurred during OCR processing."
    );
  }
};
