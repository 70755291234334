
import React from 'react';
import './Scanning.css';

const ScanningEffect = () => {
  return (
    <div className="scanning-container">
      <div className="overlay" />
      {/* <div className="content">
        <h1>Your Content Goes Here</h1>
        
      </div> */}
    </div>
  );
};

export default ScanningEffect;
