import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import logo from "../../assets/logo.svg";
import { menuItems, menuItemsAuthed } from "../../constants/JsonObjects";
import { useNavigate } from "react-router-dom";
import { getAuthToken, removeAuthToken } from "../../services/Login";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore, } from "@mui/icons-material";

const MobileMenu = ({ documentItems }: any) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const menu = getAuthToken() ? menuItemsAuthed : menuItems;

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <AppBar position="sticky" style={{marginTop:'-5rem'}} color="secondary">
      <Container maxWidth="xl" style={{}}>
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <div className="row aligncenter">
              <img
                alt="logo"
                src={logo}
                className="logo"
                onClick={() => navigate("/")}
              />
              <Typography
                style={{ fontSize: "22px", fontWeight: 600, color: "#000" }}
              >
                Whizz
              </Typography>
            </div>
          </Typography>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
              style={{ minWidth: "10px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  minWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {menu.map((page: any, index: any) =>
                  page.name !== "Supported IDs" ? (
                    <ListItemButton
                    key={index}
                      onClick={() => {
                        if (page.name === "Logout") {
                          removeAuthToken();
                        }
                        handleCloseNavMenu();
                        navigate(page.url);

                      }}
                    >
                      <ListItemText primary={page.name} />
                    </ListItemButton>
                  ) : (
                    <React.Fragment key={index}>
                      <ListItemButton onClick={handleClick}>
                        <ListItemText primary={page.name} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {documentItems?.map((item:any, index:number) => {
                            return (
                              <ListItemButton key={index} sx={{ pl: 4 }} onClick={()=>{navigate(item.Endpoint);handleCloseNavMenu()}}>
                                <ListItemText primary={item.Title} />
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  )
                  // <MenuItem key={page.id} onClick={() => {
                  //   if (page.name === "Logout") {
                  //     removeAuthToken();
                  //   }
                  //   handleCloseNavMenu();
                  //   navigate(page.url);
                  // }}>
                  //   <Typography textAlign="center">{page.name}</Typography>
                  // </MenuItem>
                )}
              </List>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MobileMenu;
