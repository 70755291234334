import * as React from "react";
import Alert from "@mui/material/Alert";
import useIsMobile from "../../hooks/useIsMobile";

export default function AlertModal({ severity, text  }: any) {
  const [open, setOpen] = React.useState(true);
  React.useEffect(()=>
  {
    setTimeout(()=>{
      setOpen(false);
    },5000)
  },[open])
    const {isMobile} = useIsMobile();
  return (
    open ? <Alert
      severity={severity}
      style={{
        
        fontSize:isMobile?'12px':'16px',

        borderRadius: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        zIndex: 9999,
        marginBottom:'1rem'
      }}
      
      >
      {text}
    </Alert>:<></>
    
  );
}
