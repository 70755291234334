import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "../src/i18n/config";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import lightThemeOptions from "./theme/lightThemeOptions";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import store from "./store";
import { hydrate, render } from "react-dom";

let lightTheme = createTheme(lightThemeOptions);
lightTheme = responsiveFontSizes(lightTheme);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
          <App />
      </ThemeProvider>
    </Provider>
  
);
const rootElement:any = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
reportWebVitals();


