import { useState } from 'react';
import { login } from '../services/Login';
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
    const navigate = useNavigate()
    
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const data = await login(formData.username, formData.password);
      if(data){
        navigate("/")
      }
      
      console.log('Login successful:', data);
    } catch (error:any) {
      
      setError(error?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  return { formData, error, loading, handleChange, handleSubmit };
};

export default useLogin;