import React, { useEffect, useRef, useState } from "react";
import { Typography, TextField, Button, Stack, Grid } from "@mui/material";
import useIsMobile from "../../hooks/useIsMobile";
import { useLogin } from "../../hooks/useLogin";
import AlertModal from "../../components/alert/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import verify from "../../assets/images/verify.png";
import Footer from "../../components/footer/Footer";
const Login = () => {
  const { isMobile, isTablet } = useIsMobile();
  const { formData, error, loading, handleChange, handleSubmit } = useLogin();
  const [resetKey,setResetKey] = useState(Date.now());
  const recaptchaRef = useRef(null);
  
  useEffect(()=>{
    if(error){
      setResetKey(Date.now());
      setRecaptchaValue("");
    }
  },[error])

  const web = !isMobile && !isTablet;

  const [recaptchaValue, setRecaptchaValue] = useState<any>(null);
  
  const handleRecaptcha = (token: any) => {
    setRecaptchaValue(token);
    
  };

  return (
    <div id="loginPaeg">
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-evenly"}
        style={{ height: "80vh" }}
        
      >
        {/* {web || width > 900 ? <Navbar /> : <MobileMenu />} */}
        {<Stack style={{width:web ? '40%' : '90%',alignItems:'center',justifyContent:'center',marginTop:'2rem'}}>
          <img
            src={verify}
            style={{ borderRadius: "25px", width:"80%" }}
            alt="Base64"
          />
        </Stack>}
        <Stack
          minHeight={"400px"}
          sx={{
            // marginTop: "1rem",
            justifyContent: "center",
            alignItems: "center",
            width: web ? "30%" : "70%",
          }}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",

              width: "100%",
              minHeight: "400px",

              padding: "3rem",
              borderRadius: "20px",
            }}
          >
            <Typography fontWeight={600} fontSize={40}>
              Login to Whizz
            </Typography>
            <TextField
              label="username"
              type="default"
              name="username"
              value={formData.username}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              InputProps={{ style: { borderRadius: "15px" } }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              label="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ style: { borderRadius: "15px" } }}
              InputLabelProps={{ shrink: true }}
            />

            {
              <ReCAPTCHA
                ref={recaptchaRef}
                key={resetKey}
                sitekey={
                  process.env.REACT_APP_RECAPTCHA
                    ? process.env.REACT_APP_RECAPTCHA
                    : ""
                }
                onChange={(token) => handleRecaptcha(token)}
              />
            }
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ width: "100%", borderRadius: "15px" }}
              disabled={loading || !recaptchaValue || recaptchaValue.length===0}
              
            >
              {loading ? "Loading.." : "Login"}
            </Button>
          </form>
          
        {error && <AlertModal severity="error" key={resetKey} text={error} />}
        <Footer />
        </Stack>
      </Grid>
    </div>
  );
};

export default Login;
