import React, { useState, useEffect } from "react";
import fetchData from "../../services/fetchIdsData";
import { motion } from "framer-motion";

import IdCard from "./idCard";
import { useParams } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import Footer from "../../components/footer/Footer";
import { Helmet } from "react-helmet";
import passport from "../../assets/images/passport.jpg";

const IdCards = () => {
  const [data, setData] = useState<any>(null);
  const params = useParams();
  useEffect(() => {
    const getData = async () => {
      const result = await fetchData();

      setData(result);
    };

    getData();
  }, []);
  const { isMobile, isTablet } = useIsMobile();
  const web = !isMobile && !isTablet;


  return (
    <div id="LebaneseId">
      <Helmet>
        
        <title>e-KYC & ID OCR Solutions - Whizz</title>
        <meta property="og:title" content="Lebanese e-KYC & ID OCR Solutions | Whizz" />
        <meta
          property="og:description"
          content={
            data?.MegaParagraph ??
            "Leverage Whizz's AI for seamless Lebanese ID verification and e-KYC integration. Fast, accurate, and compliant."
          }
        />
        <meta property="og:image" content={passport} />
        <meta name="twitter:title" content="Lebanese e-KYC & ID OCR Solutions | Whizz" />
        <meta
          name="twitter:description"
          content={
            data?.MegaParagraph ??
            "Optimize your KYC strategy with our precise Lebanese ID OCR solutions. Whizz is your partner in efficient e-KYC implementation."
          }
        />
        <meta name="twitter:image" content={passport} />
        <meta name="twitter:image" content={passport} />

      </Helmet>
      {data && (
        <motion.div
          style={{
            padding: web ? 40 : 15,
            paddingTop: 0,
            margin: web ? 20 : 10,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
         
          {data?.Documents.filter(
            (item: any) => item.Endpoint === "/" + params.id
          ).map((document: any, index: number) => (
            <IdCard key={index} document={document} />
          ))}
        </motion.div>
      )}      
      <Footer />
    </div>
  );
};

export default IdCards;
