import { motion, useAnimation, useInView } from 'framer-motion';
import React from 'react'
import useIsMobile from '../../hooks/useIsMobile';
const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.3,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
function FeatureRow({ data, index }: any)
{

  const ref = React.useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref);
  const { isMobile } = useIsMobile();
  React.useEffect(() =>
  {
    if (inView)
    {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      className="container"
      variants={ container }
      initial="hidden"
      ref={ ref }
      animate={ controls }
      whileInView={ { opacity: 1 } }
      style={ {
        display: "flex",
        flexDirection: isMobile ? "column-reverse" : index % 2 === 0 ? "row" : "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      } }
    >
      <motion.div
        key={ index }
        className="item"
        variants={ item }
        style={ {
          width: isMobile ? "100%" : "50%",
          marginBottom: "2rem",
          padding: "1rem",
          borderRadius: "10px",
        } }
      >
        <motion.h1>{ data.header }</motion.h1>
        <motion.h3 style={ { fontWeight: 500 } }>
          { data.description }
        </motion.h3>
      </motion.div>
      <motion.div
        style={ {
          width: isMobile ? "100%" : "50%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isMobile ? "center" : index % 2 === 0 ? "flex-end" : "flex-start",
          margin: "1rem",
          marginBottom: "4rem",
        } }
      >
        <img
          src={ data.img }
          style={ { borderRadius: "25px", width: "80%" } }
          alt="Base64"
        />
      </motion.div>
    </motion.div>
  )
}

export default FeatureRow
