import { motion, useAnimation, useInView } from "framer-motion";
import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import {  Grid, Paper, Typography } from "@mui/material";
import Demo from "../../components/demo/Demo";


const tabVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function IdCard({ document }: any) {
  const ref = React.useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref);
  const { isMobile, isTablet } = useIsMobile();
  const web = !isMobile && !isTablet

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <Paper elevation={0} style={{ padding: web?"20px":"5px", marginBottom: "40px" }}>
    

      <Typography variant="h4" gutterBottom>
        {document.Title}
      </Typography>
      <meta name="Title" content={document?.Title} />
{/*
      <Typography variant="body1" paragraph>
        {document.Description}
      </Typography>
       <meta name="description" content={document?.Description} />
      <Typography variant="subtitle1" paragraph>
        {document.ProcessingTime}
      </Typography>
      <meta name="description" content={document?.ProcessingTime} />
      <Typography variant="subtitle1" paragraph>
        {document.Accuracy}% Accuracy
      </Typography> */}
      <br />
      <br />
      <Grid container spacing={2}>
        {document.Pages.map((page: any, pageIndex: any) => (
          <Grid item key={pageIndex} xs={12} sm={12}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={tabVariants}
            >
              <Paper
                elevation={3}
                style={{ padding: "1rem",paddingLeft:web ? '2rem':'0.5rem', borderRadius: "10px" }}
              >
                <Grid container spacing={2} justifyContent={"space-around"} alignItems={"center"}>
                  <Grid
                    item
                    xs={web ? 6 : 12}
                    sx={{
                      padding: web? "100px":"0px",
                      flexDirection: "column",
                      display: "flex",
                      alignItems: web ? "flex-start" : "center",
                      justifyContent: "center",
                    }}
                  >
                    <br />
                    <br />
                    <Typography variant="h4" textAlign={"center"}  sx={{fontWeight: 600}} gutterBottom>
                      {page.Title}
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}  width={"90%"} paragraph>
                      {page.Paragraph}
                    </Typography>
                    <Typography variant="h6" sx={{fontWeight: 600}}  textAlign={"center"}  gutterBottom>
                      Fields:
                    </Typography>
                    <ul>
                      {page.Fields.map((field: any, fieldIndex: any) => (
                        <li key={fieldIndex} style={{ paddingBottom: '10px', fontSize: 'calc(1em * 1.05)' }}>{field} ✔</li>
                      ))}
                    </ul>
                  </Grid>
                  <Grid item xs={web ? 6: 12}>
                    <img
                      src={page.Image}
                      alt={`${page.Title} Example`}
                      style={{ width: "100%", marginTop: "10px" }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      <br />
      <br />
      <Demo showAnimation={true} />

    </Paper>
  );
}

export default IdCard;
